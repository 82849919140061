import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/arrow.svg";
const Diseases = ({ onDiseaseSelect, onNextSlide }) => {
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const { t } = useTranslation();
  const handlePreferenceSelect = (preference) => {
    if (preference === "none") {
      setSelectedPreferences(["none"]);
    } else {
      setSelectedPreferences((prev) => {
        if (prev.includes(preference)) {
          return prev.filter((item) => item !== preference);
        } else {
          return [...prev.filter((item) => item !== "none"), preference];
        }
      });
    }
  };

  const handleNextClick = () => {
    if (selectedPreferences.length > 0) {
      onDiseaseSelect(selectedPreferences);
      onNextSlide();
    } else {
      alert(t("please_select"));
    }
  };

  const digestive = [
    t("Krebs"),
    t("Ulcerative"),
    t("Diarrhea"),
    t("Diverticulosis"),
    t("Gastritis"),
    t("Constipation"),
    t("Flatulence"),
  ];

  const airways = [t("bronchial"), t("ganzjährig"), t("Chronic")];

  const skin = [
    t("Akne"),
    t("Eczema"),
    t("Hair_loss"),
    t("Neurodermatitis"),
    t("psoriasis"),
    t("Rheumatoid_arthritis"),
    t("osteoporosis"),
  ];

  const psyche = [
    t("ADHD"),
    t("Anxiety"),
    t("Autistic"),
    t("Depression"),
    t("Headaches"),
    t("Alzheimer"),
    t("Parkinson"),
    t("Fatigue"),
    t("Multiple"),
  ];

  const metabolism = [
    t("Diabetes"),
    t("high_blood_pressure"),
    t("low_blood_pressure"),
    t("Overweight"),
    t("Elevated_blood"),
    t("Vascular_diseases"),
    t("Hypothyroidism"),
  ];
  const food = [
    t("Fructose"),
    t("Gluten_intolerance"),
    t("Lactose_intolerance"),
    t("IgG_mediated"),
    t("IgE_mediated"),
    t("Histamine"),
    t("FODMAPs"),
  ];
  const food_allergies = [
    t("Eggs"),
    t("Milk"),
    t("Peanuts"),
    t("Fish"),
    t("Celery"),
    t("Crustaceans"),
    t("Lupin"),
    t("hazelnuts"),
    t("Sulphur"),
    t("mustard"),
    t("sesame"),
    t("soya"),
    t("mussels"),
  ];

  return (
    <div className="meal-plan-preferences">
      <h3>{t("disease_head")}</h3>
      <h4>{t("digestive_track")}</h4>
      <div className="preference-options">
        {digestive.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Airways")}</h4>
      <div className="preference-options">
        {airways.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Skin")}</h4>
      <div className="preference-options">
        {skin.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Psyche")}</h4>
      <div className="preference-options">
        {psyche.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Metabolism")}</h4>
      <div className="preference-options">
        {metabolism.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Food_intolerances")}</h4>
      <div className="preference-options">
        {food.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("Food_allergies")}</h4>
      <div className="preference-options">
        {food_allergies.map((preference) => (
          <button
            key={preference}
            onClick={() => handlePreferenceSelect(preference)}
            className={
              selectedPreferences.includes(preference)
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {preference}
          </button>
        ))}
      </div>
      <h4>{t("None_of_the_above")}</h4>
      <div className="preference-options">
        <button
          onClick={() => handlePreferenceSelect("none")}
          className={
            selectedPreferences.includes("none")
              ? "selected btn btn-default "
              : "btn btn-default "
          }
        >
          {t("None_of_the_above")}
        </button>
      </div>
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default Diseases;
