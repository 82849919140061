// FruitVegetableIntakeSelection.js
import React, { useState } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const FruitVegetableIntakeSelection = ({
  onFruitVegetableIntakeSelect,
  onNextSlide,
}) => {
  const [intake, setIntake] = useState(0);
  const { t } = useTranslation();

  const handleIntakeChange = (value) => {
    setIntake(value);
    onFruitVegetableIntakeSelect(value);
  };

  const formatter = (value) => {
    const labels = ["0", "1", "2", "3", t("more_than_3")];
    return labels[value];
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_many_fruits_vegetables")}</h3>
      <p>{t("handvoll")}</p>
      <div className="slider-container">
        <Slider
          min={0}
          max={4}
          value={intake}
          onChange={handleIntakeChange}
          tipFormatter={formatter}
          marks={{
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: t("more_than_3"),
          }}
        />
      </div>
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={intake === 0}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default FruitVegetableIntakeSelection;
