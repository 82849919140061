import React, { useState } from "react";
import { Slider } from "antd";
import "antd/dist/reset.css";
import FatImg1 from "../../assets/images/fat-img1.webp";
import FatImg2 from "../../assets/images/fat-img2.webp";
import FatImg3 from "../../assets/images/fat-img3.webp";
import FatImg4 from "../../assets/images/fat-img4.webp";
import FatImg5 from "../../assets/images/fat-img5.webp";
import FatImg6 from "../../assets/images/fat-img6.webp";
import FatImg7 from "../../assets/images/fat-img7.webp";
import FatImg8 from "../../assets/images/fat-img8.webp";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/images/arrow.svg";

const BodyFatLevelSelection = ({ onFatLevelSelect, onNextSlide }) => {
  const [selectedFatLevel, setSelectedFatLevel] = useState(null);
  const { t } = useTranslation();
  const fatLevelImages = [
    { range: [0, 5], image: FatImg1 },
    { range: [6, 10], image: FatImg2 },
    { range: [11, 15], image: FatImg3 },
    { range: [16, 20], image: FatImg4 },
    { range: [21, 25], image: FatImg5 },
    { range: [26, 30], image: FatImg6 },
    { range: [31, 35], image: FatImg7 },
    { range: [36, 40], image: FatImg8 },
  ];

  const getImageForFatLevel = (fatLevel) => {
    for (const { range, image } of fatLevelImages) {
      if (fatLevel >= range[0] && fatLevel <= range[1]) {
        return image;
      }
    }
    return null;
  };

  const handleFatLevelChange = (value) => {
    setSelectedFatLevel(value);
  };

  const handleNextClick = () => {
    if (selectedFatLevel !== null) {
      onFatLevelSelect(selectedFatLevel);
      onNextSlide();
    } else {
      alert("Please select a body fat level.");
    }
  };

  const currentImage = getImageForFatLevel(selectedFatLevel);

  return (
    <div className="body-fat-level-selection">
      <h3>{t("fat_level")}</h3>
      <div className="fat-box">
        <div>
          {currentImage ? (
            <img
              src={currentImage}
              alt={`Body fat level ${selectedFatLevel}`}
              className="fat-level-image"
            />
          ) : (
            <p>No image available for this fat level.</p>
          )}
        </div>
        <div>
          <Slider
            min={0}
            max={40}
            step={1}
            value={selectedFatLevel}
            onChange={handleFatLevelChange}
            marks={{
              0: "0%",
              5: "5%",
              10: "10%",
              15: "15%",
              20: "20%",
              25: "25%",
              30: "30%",
              35: "35%",
              40: "40%",
            }}
            tooltip={{ formatter: (value) => `${value}%` }}
          />
        </div>
      </div>
      {/* <p>Selected Body Fat Level: {selectedFatLevel}%</p> */}
      {/* {currentImage ? (
        <img
          src={currentImage}
          alt={`Body fat level ${selectedFatLevel}`}
          className="fat-level-image"
        />
      ) : (
        <p>No image available for this fat level.</p>
      )} */}
      <button
        className="start-button btn btn-default"
        disabled={selectedFatLevel === null}
        onClick={handleNextClick}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default BodyFatLevelSelection;
