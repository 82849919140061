import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DrinkAlcohol = ({ onAlcoholOptionSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onAlcoholOptionSelect(option);
    onNextSlide();
  };

  return (
    <div className="sugary-foods-selection">
      <h3>{t("drink_alcohol")}</h3>
      <div className="sugary-foods-buttons diet-options">
        {[
          t("Nie"),
          t("_2_Mal_pro_Woche"),
          t("_4_Mal_pro_Woche"),
          t("_6_Mal_pro_Woche"),
          t("Jeden_Tag"),
        ].map((option) => (
          <button
            key={option}
            onClick={() => handleOptionSelect(option)}
            className={
              selectedOption === option
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DrinkAlcohol;
