// NutsSeedsIntakeSelection.js
import React, { useState } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const NutsSeedsIntakeSelection = ({ onNutsSeedsSelect, onNextSlide }) => {
  const [nutsSeedsIntake, setNutsSeedsIntake] = useState(0);
  const { t } = useTranslation();

  const handleNutsSeedsChange = (value) => {
    setNutsSeedsIntake(value);

    onNutsSeedsSelect(value);
  };

  const formatter = (value) => {
    const labels = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      ">14",
    ];
    return labels[value];
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_many_portions_nuts_seeds_kernels_per_week")}</h3>
      <p>{t("portion")}</p>
      <div className="slider-container">
        <Slider
          min={0}
          max={14}
          value={nutsSeedsIntake}
          onChange={handleNutsSeedsChange}
          tipFormatter={formatter}
          marks={{
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
            11: "11",
            12: "12",
            13: "13",
            14: ">14",
          }}
        />
      </div>
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={nutsSeedsIntake === 0}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

NutsSeedsIntakeSelection.propTypes = {
  onNutsSeedsSelect: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
};

export default NutsSeedsIntakeSelection;
