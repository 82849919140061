import React, { useState, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import axios from "axios";
import NutriCheck from "../components/NutriCheck/NutriCheck";
import SlideContent from "../components/Slides/SlidesContent";
import AgeSelection from "../components/Slides/AgeSelection";
import HeightSelection from "../components/Slides/HeightSelection";
import GoalSelection from "../components/Slides/GoalSelection";
import GoalOptions from "../components/Slides/GoalOptions";
import AdditionalGoalOptions from "../components/Slides/AdditionalGoalOptions";
import AdditionalGainMuscleOptions from "../components/Slides/AdditionalGainMuscleOptions";
import AdditionalGetShreddedOptions from "../components/Slides/AdditionalGetShreddedOptions";
import DietSelection from "../components/Slides/DietSelection";
import SugaryFoodsSelection from "../components/Slides/SugaryFoodsSelection";
import WaterIntakeSelection from "../components/Slides/WaterIntakeSelection";
import MultipleIssuesSelection from "../components/Slides/MultipleIssuesSelection";
import WeightSelection from "../components/Slides/WeightSelection";
import FitnessLevelSelection from "../components/Slides/FitnessLevelSelection";
import MealPlanPreferences from "../components/Slides/MealPlanPreferences";
import AllergiesSelection from "../components/Slides/AllergiesSelection";
import BodyFatLevelSelection from "../components/Slides/BodyFatLevelSelection";
import FruitVegetableIntakeSelection from "../components/Slides/FruitVegetableIntakeSelection";
import StarchIntakeSelection from "../components/Slides/StarchIntakeSelection";
import WholeGrainIntakeSelection from "../components/Slides/WholeGrainIntakeSelection";
import DynamicQuestions from "../components/Slides/DynamicQuestions";
import ImgText from "../assets/images/essen.png";
import Arrow from "../assets/images/arrow-bck.svg";
import { useTranslation } from "react-i18next";
import EmailInput from "../components/Slides/EmailInput";
import NutsSeedsIntakeSelection from "../components/Slides/NutsSeedsIntakeSelection";
import SleepHoursSelection from "../components/Slides/SleepHoursSelection";
import WorkPercentageSelection from "../components/Slides/WorkPercentageSelection";
import VegetarianOptions from "../components/Slides/VegetarianOptions";
import Diseases from "../components/Slides/Diseases";
import DrinkAlcohol from "../components/Slides/DrinkAlcohol";
import AlcoholTypeSelection from "../components/Slides/AlcoholTypeSelection";
import AlcoholConsumptionFrequency from "../components/Slides/AlcoholConsumptionFrequency";
import OilsAndFats from "../components/Slides/OilsAndFats";

const Nutricheck = () => {
  const { t } = useTranslation();
  const [showIntro, setShowIntro] = useState(() => {
    const savedIntro = sessionStorage.getItem("showIntro");
    return savedIntro ? JSON.parse(savedIntro) : true;
  });
  const [currentSlide, setCurrentSlide] = useState(() => {
    const savedSlide = sessionStorage.getItem("currentSlide");
    return savedSlide ? parseInt(savedSlide, 10) : 0;
  });
  const [userData, setUserData] = useState(() => {
    const savedData = localStorage.getItem("userData");
    return savedData
      ? JSON.parse(savedData)
      : {
          "Choose Your Gender?": null,
          "Select Your Age Group?": null,
          "Select Your Height?": null,
          "Select Your Body Type?": null,
          "Select Your Goal?": null,
          "Select Your Specific Goal?": null,
          "Do You Stick to One of These Diets?": null,
          "How often do you eat or drink sugary foods or drinks?": null,
          "How much water do you drink every day?": null,
          "Which of the following medications are you currently taking or have you taken in the last three months?":
            [],
          "What is your current weight and how much would you like to weight?":
            null,
          "What is your fitness level?": null,
          "Choose Your Body Fat Level": null,
          "How many fruits or vegetables do you eat per day?": null,
          "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?":
            null,
          "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?":
            null,
          "How many portions of nuts, seeds and kernels do you eat per week?":
            null,
          "How many hours do you sleep on average per night?": null,
          "How many hours do you work per week?": null,
          "Have you been diagnosed with one or more illnesses or are there illnesses in your family (parents/grandparents/siblings)?":
            null,
          "Which of these vegetarian diets applies to you?": null,
          "How often do you drink alcohol per week?": null,
          "How much alcohol do you drink?": null,
          "What kind of alcohol do you drink?": null,
          "Which oils or fats do you need to prepare your meals and how much?":
            null,
          dynamicAnswers: {},
          email: "",
        };
  });
  const [email, setEmail] = useState(() => {
    const savedEmail = localStorage.getItem("email");
    return savedEmail || "";
  });
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [navigateToPayment, setNavigateToPayment] = useState(false);
  const navigate = useNavigate();
  useEffect(
    () => {
      sessionStorage.setItem("showIntro", JSON.stringify(showIntro));
      sessionStorage.setItem("currentSlide", currentSlide);
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("email", email);
    },
    [showIntro, currentSlide, userData],
    email
  );

  useEffect(() => {
    setLoading(true);
    axios
      .post("https://ntn.codefest.io/api/site/questions")
      .then((response) => {
        if (response.data.status === 200) {
          setQuestions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching the questions:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const handleGenderSelect = (gender) => {
    setUserData((prev) => ({ ...prev, "Choose Your Gender?": gender }));
  };
  useEffect(() => {
    // Save userData and email to localStorage whenever they change
    localStorage.setItem("userData", JSON.stringify(userData));
    localStorage.setItem("email", email); // Ensure email is updated in localStorage
  }, [userData, email]);
  const handleEmailSelect = async (email) => {
    const lang = localStorage.getItem("lang");
    setEmail(email);
    setUserData((prev) => ({ ...prev, email }));
    setNavigateToPayment(true);
    const updatedUserData = { ...userData, email, lang };
    setUserData(updatedUserData);

    try {
      const response = await fetch(
        "https://ntn.codefest.io/api/site/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUserData),
        }
      );

      if (response === 200) {
        navigate("/payment");
      } else {
        console.error("Failed to send data to the API");
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  useEffect(() => {
    if (navigateToPayment) {
      navigate("/payment");
    }
    const handleVegetarianOptionSelect = (option) => {
      console.log("Selected Vegetarian Option:", option);
      setUserData((prev) => ({
        ...prev,
        "Vegetarian Options": option,
      }));
    };
  }, [navigateToPayment, navigate]);
  const slides = useMemo(() => {
    const baseSlides = [
      <SlideContent
        key="gender"
        slideIndex={0}
        onGenderSelect={handleGenderSelect}
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />,
      <AgeSelection
        key="age"
        onAgeSelect={(age) =>
          setUserData((prev) => ({ ...prev, "Select Your Age Group?": age }))
        }
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />,
      // <HeightSelection
      //   key="height"
      //   onHeightSelect={(height) =>
      //     setUserData((prev) => ({ ...prev, "Select Your Height?": height }))
      //   }
      //   onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      // />,
      <HeightSelection
        key="heightWeight"
        onHeightSelect={(height) =>
          setUserData((prev) => ({ ...prev, "Select Your Height?": height }))
        }
        onWeightSelect={({ currentWeight, targetWeight, unit }) =>
          setUserData((prev) => ({
            ...prev,
            "What is your current weight and how much would you like to weight?":
              {
                currentWeight,
                targetWeight,
                unit,
              },
          }))
        }
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />,
      <GoalSelection
        key="goal"
        onGoalSelect={(goal) =>
          setUserData((prev) => ({ ...prev, "Select Your Body Type?": goal }))
        }
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />,
      <GoalOptions
        key="goalOption"
        onGoalOptionSelect={(goalOption) =>
          setUserData((prev) => ({ ...prev, "Select Your Goal?": goalOption }))
        }
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />,
    ];

    if (userData["Select Your Goal?"] === "Lose Weight") {
      baseSlides.push(
        <AdditionalGoalOptions
          key="additionalGoalOption"
          onAdditionalGoalOptionSelect={(additionalGoalOption) =>
            setUserData((prev) => ({
              ...prev,
              "Select Your Specific Goal?": additionalGoalOption,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    } else if (userData["Select Your Goal?"] === "Gain Muscle") {
      baseSlides.push(
        <AdditionalGainMuscleOptions
          key="additionalGainMuscleOption"
          onAdditionalGoalOptionSelect={(additionalGoalOption) =>
            setUserData((prev) => ({
              ...prev,
              "Select Your Specific Goal?": additionalGoalOption,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    } else if (userData["Select Your Goal?"] === "Get Shredded") {
      baseSlides.push(
        <AdditionalGetShreddedOptions
          key="additionalGetShreddedOption"
          onAdditionalGoalOptionSelect={(additionalGoalOption) =>
            setUserData((prev) => ({
              ...prev,
              "Select Your Specific Goal?": additionalGoalOption,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }

    if (userData["Select Your Specific Goal?"]) {
      baseSlides.push(
        <DietSelection
          key="diet"
          onDietSelect={(diet) =>
            setUserData((prev) => ({
              ...prev,
              "Do You Stick to One of These Diets?": diet,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (
      userData["Do You Stick to One of These Diets?"]?.includes("Vegetarian") ||
      userData["Do You Stick to One of These Diets?"]?.includes("vegetarisch")
    ) {
      baseSlides.push(
        <VegetarianOptions
          key="vegetarianOptions"
          onVegetarianOptionSelect={(option) =>
            setUserData((prev) => ({
              ...prev,
              "Which of these vegetarian diets applies to you?": option,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["Do You Stick to One of These Diets?"]) {
      baseSlides.push(
        <FitnessLevelSelection
          key="fitnessLevel"
          onFitnessLevelSelect={(fitnessLevel) =>
            setUserData((prev) => ({
              ...prev,
              "What is your fitness level?": fitnessLevel,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["What is your fitness level?"]) {
      baseSlides.push(
        <BodyFatLevelSelection
          key="bodyFatLevel"
          onFatLevelSelect={(bodyFatLevel) =>
            setUserData((prev) => ({
              ...prev,
              "Choose Your Body Fat Level": bodyFatLevel,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["Choose Your Body Fat Level"]) {
      baseSlides.push(
        <WaterIntakeSelection
          key="waterIntake"
          onWaterIntakeSelect={(waterIntake) =>
            setUserData((prev) => ({
              ...prev,
              "How much water do you drink every day?": waterIntake,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["How much water do you drink every day?"]) {
      baseSlides.push(
        <FruitVegetableIntakeSelection
          key="fruitVegetableIntake"
          onFruitVegetableIntakeSelect={(fruitVegetableIntake) =>
            setUserData((prev) => ({
              ...prev,
              "How many fruits or vegetables do you eat per day?":
                fruitVegetableIntake,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["How many fruits or vegetables do you eat per day?"]) {
      baseSlides.push(
        <StarchIntakeSelection
          key="fruitVegetableIntake"
          onStarchIntakeSelect={(starchIntakeIntake) =>
            setUserData((prev) => ({
              ...prev,
              "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?":
                starchIntakeIntake,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (
      userData[
        "How often per day do you consume starch sources such as potatoes, rice, bread, pasta, cereal flakes, etc.?"
      ]
    ) {
      baseSlides.push(
        <WholeGrainIntakeSelection
          key="wholeGrainIntake"
          onWholeGrainIntakeSelect={(wholeGrainIntake) =>
            setUserData((prev) => ({
              ...prev,
              "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?":
                wholeGrainIntake,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (
      userData[
        "How many servings of the starch suppliers consist of wholemeal flour or the whole grain?"
      ]
    ) {
      baseSlides.push(
        <NutsSeedsIntakeSelection
          key="nutsSeedsIntakeSelection"
          onNutsSeedsSelect={(nutSeedsIntake) =>
            setUserData((prev) => ({
              ...prev,
              "How many portions of nuts, seeds and kernels do you eat per week?":
                nutSeedsIntake,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }

    if (
      userData[
        "How many portions of nuts, seeds and kernels do you eat per week?"
      ]
    ) {
      baseSlides.push(
        <DrinkAlcohol
          key="alcohol"
          onAlcoholOptionSelect={(alcohol) =>
            setUserData((prev) => ({
              ...prev,
              "How often do you drink alcohol per week?": alcohol,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    const drinkAlcoholAnswer =
      userData["How often do you drink alcohol per week?"];
    const skipAlcoholSlides =
      drinkAlcoholAnswer === "Never" || drinkAlcoholAnswer === "Nie";

    if (!skipAlcoholSlides && drinkAlcoholAnswer) {
      baseSlides.push(
        <AlcoholTypeSelection
          key="alcoholtype"
          onAlcoholTypeSelect={(alcoholType) =>
            setUserData((prev) => ({
              ...prev,
              "What kind of alcohol do you drink?": alcoholType,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (!skipAlcoholSlides && userData["What kind of alcohol do you drink?"]) {
      baseSlides.push(
        <AlcoholConsumptionFrequency
          key="alcoholFrequency"
          onAlcoholFrequencySelect={(alcoholFrequency) =>
            setUserData((prev) => ({
              ...prev,
              "How much alcohol do you drink?": alcoholFrequency,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }

    if (drinkAlcoholAnswer) {
      baseSlides.push(
        <SleepHoursSelection
          key="sleepHourSelection"
          onSleepHoursSelect={(SleepHourSelection) =>
            setUserData((prev) => ({
              ...prev,
              "How many hours do you sleep on average per night?":
                SleepHourSelection,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["How many hours do you sleep on average per night?"]) {
      baseSlides.push(
        <WorkPercentageSelection
          key="workPercentageSelection"
          onWorkPercentageSelect={(WorkPercentageSelection) =>
            setUserData((prev) => ({
              ...prev,
              "How many hours do you work per week?": WorkPercentageSelection,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (userData["How many hours do you work per week?"]) {
      baseSlides.push(
        <Diseases
          key="diseases"
          onDiseaseSelect={(diseaseSelect) =>
            setUserData((prev) => ({
              ...prev,
              "Have you been diagnosed with one or more illnesses or are there illnesses in your family (parents/grandparents/siblings)?":
                diseaseSelect,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (
      userData[
        "Have you been diagnosed with one or more illnesses or are there illnesses in your family (parents/grandparents/siblings)?"
      ]
    ) {
      baseSlides.push(
        <OilsAndFats
          key="oilsAndFats"
          onSelectionComplete={(oilsAndFatsPreferences) =>
            setUserData((prev) => ({
              ...prev,
              "Which oils or fats do you need to prepare your meals and how much?":
                oilsAndFatsPreferences,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }
    if (
      userData[
        "Which oils or fats do you need to prepare your meals and how much?"
      ]
    ) {
      baseSlides.push(
        <SugaryFoodsSelection
          key="sugaryFoodsFrequency"
          onSugaryFoodsSelect={(sugaryFoodsFrequency) =>
            setUserData((prev) => ({
              ...prev,
              "How often do you eat or drink sugary foods or drinks?":
                sugaryFoodsFrequency,
            }))
          }
          onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
        />
      );
    }

    if (userData["Choose Your Gender?"]) {
      console.log(
        "userData['Choose Your Gender?']:",
        userData["Choose Your Gender?"]
      );
      const gender = userData["Choose Your Gender?"].toLowerCase();
      console.log("Gender for comparison:", gender);
      const filteredQuestions = questions.filter(
        (question) =>
          question.q_for.toLowerCase() === "all" ||
          question.q_for.toLowerCase() === gender
      );
      console.log("Filtered questions:", filteredQuestions);

      filteredQuestions.forEach((question) => {
        const questionData = JSON.parse(question.question);
        const englishQuestion = questionData.english;

        baseSlides.push(
          <DynamicQuestions
            key={`dynamic-${question.id}`}
            question={question}
            loading={loading}
            onAnswerSelect={(answer) => {
              setUserData((prev) => ({
                ...prev,
                dynamicAnswers: {
                  ...prev.dynamicAnswers,
                  [englishQuestion]: answer,
                },
              }));
            }}
            onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
          />
        );
      });
    }
    baseSlides.push(
      <EmailInput
        key="emailInput"
        email={email}
        onEmailSelect={handleEmailSelect}
        onNextSlide={() => setCurrentSlide((prev) => prev + 1)}
      />
    );

    return baseSlides;
  }, [userData, questions, loading]);

  const handleStart = () => {
    setShowIntro(false);
  };

  const handlePrevious = () => {
    setCurrentSlide((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev < slides.length - 1 ? prev + 1 : prev));
  };

  const progressPercentage = ((currentSlide + 1) / slides.length) * 100;

  return (
    <div className="nutricheck">
      {!showIntro && (
        <div className="nutri-progress">
          <div className="progress-bar">
            <div
              className="progress-bar-inner"
              style={{ width: `${progressPercentage}%` }}
            ></div>
            <p className="progress-bar-text">
              {currentSlide + 1}/{slides.length}
            </p>
          </div>
        </div>
      )}
      <NutriCheck
        showIntro={showIntro}
        currentSlide={currentSlide}
        slides={slides}
        onStart={handleStart}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
      <div className="navigation-buttons">
        <button
          onClick={handlePrevious}
          className="btn btn-default"
          disabled={currentSlide === 0}
        >
          <img src={Arrow} alt="arrow" />
          {t("back")}
        </button>
      </div>
      <img
        className="mx-auto d-block pb-5 img-fluid"
        src={ImgText}
        alt="text"
      />
    </div>
  );
};

export default Nutricheck;
