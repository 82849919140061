import React, { useState } from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// pages
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Nutricheck from "./pages/Nutricheck";
import PaymentPage from "./pages/Payment";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";

function App() {
  const lan = localStorage.getItem("lang") || "gm";
  const [language, setLanguage] = useState(lan);
  // const userEmail = localStorage.getItem("email");

  // const canAccessPayment = !!userEmail;
  return (
    <BrowserRouter>
      <Header language={language} setLanguage={setLanguage} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nutricheck" element={<Nutricheck />} />
        {/* <Route
          path="/payment"
          element={
            canAccessPayment ? <PaymentPage /> : <Navigate to="/nutricheck" />
          }
        /> */}
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
