// WholeGrainIntakeSelection.js
import React, { useState } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const WholeGrainIntakeSelection = ({
  onWholeGrainIntakeSelect,
  onNextSlide,
}) => {
  const [wholeGrainIntake, setWholeGrainIntake] = useState(0);
  const { t } = useTranslation();

  const handleWholeGrainChange = (value) => {
    setWholeGrainIntake(value);
    onWholeGrainIntakeSelect(value);
  };

  const formatter = (value) => {
    const labels = ["0", "1", "2", "3", "4", "über 5"];
    return labels[value];
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_many_servings_wholegrain")}</h3>

      <div className="slider-container">
        <Slider
          min={0}
          max={5}
          value={wholeGrainIntake}
          onChange={handleWholeGrainChange}
          tipFormatter={formatter}
          marks={{
            0: "0",
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "über 5",
          }}
        />
      </div>
      <button className="start-button btn btn-default" onClick={onNextSlide}>
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

WholeGrainIntakeSelection.propTypes = {
  onWholeGrainSelect: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
};

export default WholeGrainIntakeSelection;
