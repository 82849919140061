import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "../assets/sass/_payment.scss";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

const PaymentForm = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const fixedAmount = "10.00";
  const currency = "eur";

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      setLoading(true);
      setError(null);
      const email = localStorage.getItem("email");
      const cardNumberElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          email: email,
        },
      });

      if (error) {
        setError(error.message);
        setLoading(false);
        return;
      }

      const response = await fetch(
        "https://ntn.codefest.io/api/payment/create-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            email: email,
            amount: fixedAmount,
            currency: currency,
            couponCode: props.couponCode,
          }),
        }
      );
      const paymentResult = await response.json();

      if (paymentResult.error) {
        setError(paymentResult.error);
        setLoading(false);
        return;
      }

      const { clientSecret } = paymentResult;
      console.log("Client Secret:", clientSecret);

      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethod.id,
        }
      );

      if (confirmError) {
        setError(confirmError.message);
        setLoading(false);
        return;
      }
      setLoading(false);
      props.onPaymentSuccess();
      try {
        const emailResponse = await fetch(
          "https://ntn.codefest.io/api/payment/send-pdf",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
            }),
          }
        );

        const emailResult = await emailResponse.json();

        if (emailResult.error) {
          setError(emailResult.error);
          setLoading(false);
          return;
        }

        alert("Payment successful and email sent!");
      } catch (emailError) {
        setError("Failed to send email: " + emailError.message);
        setLoading(false);
      }
    },
  }));

  return (
    <form className="payment-form">
      {loading && (
        <div className="payment-loading">
          <Spin animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spin>
        </div>
      )}
      <div className="row">
        <div className="form-group col-md-12">
          <label htmlFor="card-number">{t("card_number")}</label>
          <CardNumberElement id="card-number" className="form-control" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="card-expiry">{t("expiration")}</label>
          <CardExpiryElement id="card-expiry" className="form-control" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="card-cvc">CVC</label>
          <CardCvcElement id="card-cvc" className="form-control" />
        </div>
      </div>
      {error && <div className="payment-error">{error}</div>}
    </form>
  );
});

export default PaymentForm;
