import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AlcoholTypeSelection = ({ onAlcoholTypeSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onAlcoholTypeSelect(option);
    onNextSlide();
  };

  return (
    <div className="sugary-foods-selection">
      <h3>{t("alcohol_type")}</h3>
      <div className="sugary-foods-buttons diet-options">
        {[
          { text: t("Wein"), icon: "🍷" },
          { text: t("Beer"), icon: "🍺" },
          { text: t("Spirits"), icon: "🥃" },
          { text: t("Cocktails"), icon: "🍹" },
        ].map(({ text, icon }) => (
          <button
            key={text}
            onClick={() => handleOptionSelect(text)}
            className={
              selectedOption === text
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {text} <span className="icon">{icon}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AlcoholTypeSelection;
