import React from "react";
import Logo from "../../assets/images/nutriteam-logo.svg";
import Mail from "../../assets/images/mail.svg";
import Phone from "../../assets/images/phone.svg";
import Location from "../../assets/images/location.svg";
import "../../assets/sass/_footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-logo">
        <img className="mx-auto d-block" src={Logo} alt="logo" />
      </div>
      <ul className="footer-list list-unstyled">
        <li>
          <Link>{t("home")}</Link>
        </li>
        <li>
          <Link>{t("about_us")}</Link>
        </li>
        <li>
          <Link>Faq</Link>
        </li>
        {/* <li>
          <Link>{t("offers")}</Link>
        </li>
        <li>
          <Link>{t("references")}</Link>
        </li> */}

        <li>
          <Link>Blog</Link>
        </li>
        <li>
          <Link>Nutricheck</Link>
        </li>
      </ul>
      <div className="footer-box">
        <div className="contact">
          <div>
            <img src={Mail} alt="envelope" />
            info@nutriteam.ch
          </div>
          <div>
            <img src={Phone} alt="phone" />
            031 371 70 22
          </div>
          <div>
            <img src={Location} alt="location" />
            3007 Bern, Switzerland
          </div>
        </div>
        <p className="copyright-text mb-0">
          © 2023 Ernährungsberater. Alle Rechte vorbehalten.
        </p>
      </div>
    </div>
  );
};

export default Footer;
