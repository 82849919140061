// SleepHoursSelection.js
import React, { useState } from "react";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SleepHoursSelection = ({ onSleepHoursSelect, onNextSlide }) => {
  const [sleepHours, setSleepHours] = useState(null);
  const { t } = useTranslation();

  const handleSleepHoursChange = (value) => {
    setSleepHours(value);

    onSleepHoursSelect(value);
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("how_many_hours_sleep_per_night")}</h3>
      <div className="slider-container">
        <Slider
          min={4}
          max={16}
          value={sleepHours}
          onChange={handleSleepHoursChange}
          tipFormatter={(value) => `${value}h`}
          marks={{
            4: "4h",
            5: "5h",
            6: "6h",
            7: "7h",
            8: "8h",
            9: "9h",
            10: "10h",
            11: "11h",
            12: "12h",
            13: "13h",
            14: "14h",
            15: "15h",
            16: "16h",
          }}
        />
      </div>
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={sleepHours === null}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

SleepHoursSelection.propTypes = {
  onSleepHoursSelect: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
};

export default SleepHoursSelection;
