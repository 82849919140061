import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import "../assets/sass/_payment.scss";
import Success from "../assets/images/payment-success.jpg";
import { useTranslation } from "react-i18next";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleClose = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="container">
      <div className="payment-success-container">
        <img
          style={{ maxWidth: "500px" }}
          className="img-fluid"
          src={Success}
          alt="success-img"
        />
        <h3>{t("payment_head")}</h3>
        <p>{t("payment_para")}</p>
        <Button className="close-btn" type="primary" onClick={handleClose}>
          {t("payment_btn")}
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
