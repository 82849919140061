import React, { useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import { Slider } from "antd";
import Add from "../../assets/images/plus.svg";
import Subtract from "../../assets/images/minus.svg";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const HeightSelection = ({ onHeightSelect, onWeightSelect, onNextSlide }) => {
  const [height, setHeight] = useState(160);
  const [currentWeight, setCurrentWeight] = useState("");
  const [targetWeight, setTargetWeight] = useState("");
  const [unit, setUnit] = useState("kg");
  const { t } = useTranslation();

  const handleHeightChange = (value) => {
    setHeight(value);
    onHeightSelect(value);
  };

  const handleCurrentWeightChange = (e) => {
    const value = e.target.value;
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setCurrentWeight(value);
    }
  };

  const handleTargetWeightChange = (e) => {
    const value = e.target.value;
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setTargetWeight(value);
    }
  };

  const handleUnitChange = (newUnit) => {
    if (newUnit !== unit) {
      const conversionFactor = newUnit === "kg" ? 0.453592 : 2.20462;
      const convertedCurrentWeight = (currentWeight * conversionFactor).toFixed(
        2
      );
      const convertedTargetWeight = (targetWeight * conversionFactor).toFixed(
        2
      );
      setCurrentWeight(convertedCurrentWeight);
      setTargetWeight(convertedTargetWeight);
      setUnit(newUnit);
    }
  };

  const handleNextClick = () => {
    if (currentWeight > 0 && targetWeight > 0) {
      onWeightSelect({ currentWeight, targetWeight, unit });
      onNextSlide();
    } else {
      alert("Please enter valid weights greater than zero.");
    }
  };

  const incrementWeight = (setWeight, weight) => {
    const newWeight = (parseInt(weight || 0) + 1).toString();
    if (newWeight.length <= 3) {
      setWeight(newWeight);
    }
  };

  const decrementWeight = (setWeight, weight) => {
    const newWeight = (parseInt(weight || 0) - 1).toString();
    if (newWeight >= 0 && newWeight.length <= 3) {
      setWeight(newWeight);
    }
  };

  const formatter = (value) => `${value} cm`;

  return (
    <div className="height-weight-selection">
      <div className="age-selection">
        <h3>{t("select_height")}</h3>
        <p>{t("select_height_p")}</p>
        <Slider
          min={50}
          max={250}
          value={height}
          onChange={handleHeightChange}
          tipFormatter={formatter}
        />
      </div>
      <div className="weight-selection">
        <h3>{t("current_weight")}</h3>
        <div className="weight-inputs">
          <div className="input-header">
            <p>{t("select_unit")}</p>
            <div>
              <button
                className={`btn btn-default ${unit === "kg" ? "active" : ""}`}
                onClick={() => handleUnitChange("kg")}
              >
                Kg
              </button>
              <button
                className={`btn btn-default ${unit === "lb" ? "active" : ""}`}
                onClick={() => handleUnitChange("lb")}
              >
                lb
              </button>
            </div>
          </div>
          <div className="input-group">
            <label>
              {t("current_weight_lb")} ({unit}):
            </label>
            <div className="weight-input-wrapper">
              <input
                className="form-control"
                type="text"
                value={currentWeight}
                onChange={handleCurrentWeightChange}
                placeholder={t("Current_Weight")}
                maxLength="3"
              />
              <div>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() =>
                    incrementWeight(setCurrentWeight, currentWeight)
                  }
                >
                  <img src={Add} alt="add" />
                </button>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() =>
                    decrementWeight(setCurrentWeight, currentWeight)
                  }
                >
                  <img src={Subtract} alt="subtract" />
                </button>
              </div>
            </div>
          </div>
          <div className="input-group">
            <label>
              {t("target_weight")} ({unit}):
            </label>
            <div className="weight-input-wrapper">
              <input
                className="form-control"
                type="text"
                value={targetWeight}
                onChange={handleTargetWeightChange}
                placeholder={t("Target_Weight")}
                maxLength="3"
              />
              <div>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() => incrementWeight(setTargetWeight, targetWeight)}
                >
                  <img src={Add} alt="add" />
                </button>
                <button
                  className="btn btn-default increment-decrement-button"
                  onClick={() => decrementWeight(setTargetWeight, targetWeight)}
                >
                  <img src={Subtract} alt="subtract" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleNextClick}
        className="btn btn-default start-button"
        disabled={currentWeight <= 0 || targetWeight <= 0}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default HeightSelection;
