// components/Slides/AgeSelection.jsx
import React, { useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const AgeSelection = ({ onAgeSelect, onNextSlide }) => {
  const [age, setAge] = useState(18);
  const [ageSelected, setAgeSelected] = useState(false);
  const { t } = useTranslation();

  const handleAgeChange = (value) => {
    setAge(value);
    setAgeSelected(true);
    onAgeSelect(value);
  };

  return (
    <div className="age-selection">
      <h3>{t("select_age")}</h3>
      <p>{t("select_age_p")}</p>
      <Slider min={1} max={100} value={age} onChange={handleAgeChange} />
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={!ageSelected}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default AgeSelection;
