import React from "react";
// scss
import "../../assets/sass/_gallery.scss";
// scss
// images
import Img1 from "../../assets/images/gallery.svg";
import Women from "../../assets/images/women.png";
import { Link } from "react-router-dom";
// images
import { useTranslation } from "react-i18next";
const Gallery = () => {
  const { t } = useTranslation();
  return (
    <div className="gallery">
      <img className="img-fluid" src={Img1} alt="gallery-img" />
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="gallery-box">
            <img className="img-fluid" src={Women} alt="women" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="gallery-box">
            <h3>{t("How_it_works")}</h3>
            <p>{t("get_started")}</p>
            <p>
              <strong>1.</strong> {t("point_one")}
            </p>
            <p>
              <strong>2.</strong> {t("point_two")}
            </p>
            <p>
              <strong>3.</strong> {t("point_three")}
            </p>

            <Link to="/nutricheck">{t("here_work")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
