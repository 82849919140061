import React, { useState } from "react";
import vegetarianImg from "../../assets/images/avacado.svg";
import veganImg from "../../assets/images/leaves.svg";
import ketoImg from "../../assets/images/keto.svg";
import mediterraneanImg from "../../assets/images/med.svg";
import Fasting from "../../assets/images/fasting.png";
import LowCarb from "../../assets/images/low-carb.png";
import Mixed from "../../assets/images/mixed-diet.png";
import WholeFood from "../../assets/images/whole-food.png";
import Alkaline from "../../assets/images/alkaline-diet.png";
import Food from "../../assets/images/food.png";
import Fodmap from "../../assets/images/stomach.png";
import noneImg from "../../assets/images/close.svg";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const getDietImage = (diet, t) => {
  const dietImages = {
    [t("vegetarian")]: vegetarianImg,
    [t("vegan")]: veganImg,
    [t("keto")]: ketoImg,
    [t("mediterranean")]: mediterraneanImg,
    [t("Alkaline_fasting")]: Alkaline,
    [t("Low_Carb")]: LowCarb,
    [t("FODMAP")]: Fodmap,
    [t("Intermittent")]: Fasting,
    [t("Mixed_diet")]: Mixed,
    [t("Wholefood")]: WholeFood,
    [t("Flexitarians")]: Food,
    [t("none")]: noneImg,
  };

  return dietImages[diet];
};

const DietSelection = ({ onDietSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const diets = [
    t("vegetarian"),
    t("vegan"),
    t("keto"),
    t("mediterranean"),
    t("Alkaline_fasting"),
    t("Low_Carb"),
    t("FODMAP"),
    t("Intermittent"),
    t("Mixed_diet"),
    t("Wholefood"),
    t("Flexitarians"),
    t("none"),
  ];
  const [selectedDiets, setSelectedDiets] = useState([]);

  const handleDietToggle = (diet) => {
    if (diet === t("none")) {
      setSelectedDiets((prev) => (prev.includes(diet) ? [] : [diet]));
    } else {
      setSelectedDiets((prev) => {
        if (prev.includes(t("none"))) {
          return [diet];
        } else if (prev.includes(diet)) {
          return prev.filter((d) => d !== diet);
        } else {
          return [...prev, diet];
        }
      });
    }
  };

  const handleNextClick = () => {
    onDietSelect(selectedDiets);
    onNextSlide();
  };

  return (
    <div className="multiple-issues-selection">
      <h3>{t("stick")}</h3>
      <div className="issues-options">
        {diets.map((diet) => (
          <div key={diet} className="issue-option">
            <label className="form-control">
              <input
                type="checkbox"
                name="checkbox-checked"
                checked={selectedDiets.includes(diet)}
                onChange={() => handleDietToggle(diet)}
              />
              <img
                src={getDietImage(diet, t)}
                className="diet-image"
                alt={diet}
              />
              {diet}
            </label>
          </div>
        ))}
      </div>
      <button
        onClick={handleNextClick}
        disabled={selectedDiets.length === 0}
        className="btn btn-default start-button"
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default DietSelection;
