import React, { useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/PaymentForm";
import "../assets/sass/_payment.scss";
import Lock from "../assets/images/lock.svg";
import Fruits from "../assets/images/fruits.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_test_51PttZGCNNJb7RDVDRYqLCUO1xWSeld5kVHmi9zklui0tW8Invk3E6xOwSTMLF65PzJrhRDhv9fVi0dbxvaLaK0i100bbzlqHjA"
);

const PaymentPage = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState("");

  const handlePaymentSuccess = () => {
    console.log("Payment successful!");
    navigate("/payment-success");
  };

  const handlePayNowClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    } else {
      console.error("Payment form reference is null.");
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="container-fluid">
        <div className="payment-main">
          <div className="row">
            <div className="col-md-8">
              <div className="payment-method">
                <h2>{t("pay_now")}</h2>
                <h3>{t("enter_details")}</h3>

                <PaymentForm
                  ref={formRef}
                  onPaymentSuccess={handlePaymentSuccess}
                  couponCode={couponCode}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="pay-now">
                <div className="payment-total">
                  <h3>{t("total")}</h3>
                  <h3>€10,00</h3>
                </div>
                <div className="form-group">
                  <label htmlFor="coupon-code">{t("coupon")}</label>
                  <input
                    type="text"
                    id="coupon-code"
                    className="form-control"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                </div>
                <button
                  onClick={handlePayNowClick}
                  className="btn btn-default pay-btn"
                >
                  {t("pay_now_btn")}
                </button>
                <p className="payment-secure">
                  <img src={Lock} alt="lock" />
                  {t("guaranteed")}
                </p>
                <img
                  className="mx-auto d-block img-fluid"
                  src={Fruits}
                  alt="fruits"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default PaymentPage;
