import React from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY.png";
import Average from "../../assets/images/average.png";
import Heavy from "../../assets/images/BODYBUILDER 1.png";
import Female from "../../assets/images/female.png";
import { useTranslation } from "react-i18next";

const AdditionalGainMuscleOptions = ({
  onAdditionalGoalOptionSelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: Female,
          average: Female,
          heavy: Female,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Heavy,
        };
  return (
    <div className="additional-goal-options goal-selection">
      <h3>{t("specific_goal")}</h3>
      <div className="goal-options">
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Athlete");
            onNextSlide();
          }}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("athlete")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Hero");
            onNextSlide();
          }}
        >
          <img src={images.average} alt="slim-img" />
          <div> {t("hero")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Bodybuilder");
            onNextSlide();
          }}
        >
          <img src={images.heavy} alt="slim-img" />
          <div>{t("bodybuilder")}</div>
        </button>
      </div>
    </div>
  );
};

export default AdditionalGainMuscleOptions;
