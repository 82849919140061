import React from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY.png";
import Average from "../../assets/images/average.png";
import Heavy from "../../assets/images/heavy.png";
import Female from "../../assets/images/female.png";
import { useTranslation } from "react-i18next";

const GoalSelection = ({ onGoalSelect, onNextSlide }) => {
  const { t } = useTranslation();
  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: Female,
          average: Female,
          heavy: Female,
        }
      : {
          slim: Slim,
          average: Average,
          heavy: Heavy,
        };
  const handleGoalClick = (goal) => {
    onGoalSelect(goal);
    onNextSlide();
  };

  return (
    <div className="goal-selection">
      <h3>{t("select_body_type")}</h3>
      <div className="goal-options">
        <button
          className="btn btn-default"
          onClick={() => handleGoalClick("slim")}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("slim")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => handleGoalClick("average")}
        >
          <img src={images.average} alt="slim-img" />
          <div>{t("average")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => handleGoalClick("heavy")}
        >
          <img src={images.heavy} alt="slim-img" />
          <div>{t("heavy")}</div>
        </button>
      </div>
    </div>
  );
};

export default GoalSelection;
