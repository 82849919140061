import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SugaryFoodsSelection = ({ onSugaryFoodsSelect, onNextSlide }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSugaryFoodsSelect(option);
    onNextSlide();
  };

  return (
    <div className="sugary-foods-selection">
      <h3>{t("sugary_food")}</h3>
      <div className="sugary-foods-buttons diet-options">
        {[
          { text: t("not_often"), icon: "😎" },
          { text: t("three_times"), icon: "🍪" },
          { text: t("every_day"), icon: "🤤" },
        ].map(({ text, icon }) => (
          <button
            key={text}
            onClick={() => handleOptionSelect(text)}
            className={
              selectedOption === text
                ? "selected btn btn-default"
                : "btn btn-default"
            }
          >
            {text} <span className="icon">{icon}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SugaryFoodsSelection;
