import React from "react";
import Charact from "../../assets/images/charat.svg";
import "../../assets/sass/_testimonials.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Person from "../../assets/images/testi.png";
import Quotes from "../../assets/images/quotes.svg";
import { useTranslation } from "react-i18next";

import Slider from "react-slick";
const Testimonials = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const PrevArrow = ({ className, style, onClick }) => (
    <div className={`${className} custom-arrow prev-arrow`} onClick={onClick}>
      Prev
    </div>
  );

  const NextArrow = ({ className, style, onClick }) => (
    <div className={`${className} custom-arrow next-arrow`} onClick={onClick}>
      Next
    </div>
  );
  const CharacteristicsData = [
    {
      image: Person,
      name: "Jennifer Anderson",
      description:
        "Ich kann Nutritionist nicht genug für ihr individuelles Ernährungscoaching danken. Es hat meine Herangehensweise an das Essen völlig verändert und mir geholfen, die überflüssigen Pfunde loszuwerden. Sehr empfehlenswert!",
    },
    {
      image: Person,
      name: "Jennifer Anderson",
      description:
        "Ich kann Nutritionist nicht genug für ihr individuelles Ernährungscoaching danken. Es hat meine Herangehensweise an das Essen völlig verändert und mir geholfen, die überflüssigen Pfunde loszuwerden. Sehr empfehlenswert!",
    },
    {
      image: Person,
      name: "Jennifer Anderson",
      description:
        "Ich kann Nutritionist nicht genug für ihr individuelles Ernährungscoaching danken. Es hat meine Herangehensweise an das Essen völlig verändert und mir geholfen, die überflüssigen Pfunde loszuwerden. Sehr empfehlenswert!",
    },
  ];
  return (
    <div className="testimonials">
      <img className="img-fluid" src={Charact} alt="charat-logo" />
      <h3>{t("practice_h")}</h3>
      <p>{t("practice_p")} </p>
      <p>{t("feedback_p")}</p>
      <Slider {...settings}>
        {CharacteristicsData.map((data, index) => (
          <>
            <div key={index} className="testimonials-box card">
              <div className="testimonials-box-cont">
                <img className="img-fluid" src={Quotes} alt="quotes" />
                <p>{data.description}</p>
              </div>
              <div className="card-footer">
                <img className="img-fluid" src={data.image} alt="icon" />
                <h5>{data.name}</h5>
              </div>
            </div>
          </>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
