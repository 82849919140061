import React, { useState, useEffect } from "react";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import { Select } from "antd";

const DynamicQuestions = ({
  question,
  onAnswerSelect,
  onNextSlide,
  loading,
}) => {
  const { t } = useTranslation();
  const [parsedQuestion, setParsedQuestion] = useState("");
  const [parsedOptions, setParsedOptions] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isLargeOptionSet, setIsLargeOptionSet] = useState(false);

  const updateLanguageData = () => {
    const lang = localStorage.getItem("lang") || "gm";
    const languageMap = {
      gm: "german",
      fr: "french",
      en: "english",
    };

    const selectedLanguage = languageMap[lang] || "english";
    const parsedQuestionData = JSON.parse(question.question);

    setParsedQuestion(
      parsedQuestionData[selectedLanguage] || parsedQuestionData.english
    );

    const parsedOptionsData = JSON.parse(question.options);
    const languageOptions = {};
    for (const [key, value] of Object.entries(parsedOptionsData)) {
      const option = JSON.parse(value);
      languageOptions[key] = option[selectedLanguage] || option.english;
    }
    setParsedOptions(languageOptions);
    setIsLargeOptionSet(Object.keys(languageOptions).length > 30);
  };

  useEffect(() => {
    updateLanguageData();
  }, [question]);

  useEffect(() => {
    updateLanguageData();
  }, [localStorage.getItem("lang")]);

  const handleOptionToggle = (value) => {
    let updatedAnswers;
    if (value.toLowerCase() === "none" || value.toLowerCase() === "keines") {
      updatedAnswers = selectedAnswers.includes(value) ? [] : [value];
    } else {
      const isSelected = selectedAnswers.includes(value);
      updatedAnswers = isSelected
        ? selectedAnswers.filter((answer) => answer !== value)
        : [
            ...selectedAnswers.filter(
              (answer) =>
                answer.toLowerCase() !== "none" &&
                answer.toLowerCase() !== "keines"
            ),
            value,
          ];
    }

    setSelectedAnswers(updatedAnswers);
    onAnswerSelect(updatedAnswers);
  };

  const handleNextClick = () => {
    onAnswerSelect(selectedAnswers);
    onNextSlide();
  };

  const handleOptionSelect = (value) => {
    const isSelected = selectedAnswers.includes(value);
    const updatedAnswers = isSelected
      ? selectedAnswers.filter((answer) => answer !== value)
      : [...selectedAnswers, value];

    setSelectedAnswers(updatedAnswers);
    onAnswerSelect(updatedAnswers);
    onNextSlide();
  };

  const handleSelectChange = (values) => {
    setSelectedAnswers(values);
    onAnswerSelect(values);
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="spinner-border d-flex" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="dynamic-questions">
      <h3>{parsedQuestion}</h3>
      {isLargeOptionSet || question.q_type === "largeoptionselect" ? (
        <div className="large-options-container">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={t("Select Options")}
            onChange={handleSelectChange}
            value={selectedAnswers}
          >
            {Object.entries(parsedOptions).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
          <button
            onClick={handleNextClick}
            className="btn btn-default start-button"
            style={{ marginTop: "10px" }}
          >
            {t("next_btn")}
            <img className="img-fluid" src={Arrow} alt="arrow" />
          </button>
        </div>
      ) : question.q_type === "MS" ? (
        <div className="diet-selection">
          <div className="diet-options">
            {Object.entries(parsedOptions).map(([key, value]) => (
              <button
                key={key}
                onClick={() => handleOptionSelect(value)}
                className={
                  selectedAnswers.includes(value)
                    ? "selected btn btn-default"
                    : "btn btn-default"
                }
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      ) : question.q_type === "MM" ? (
        <div className="multiple-issues-selection">
          <div className="issues-options">
            {Object.entries(parsedOptions).map(([key, value]) => (
              <div key={key} className="issue-option">
                <label className="form-control">
                  <input
                    type="checkbox"
                    checked={selectedAnswers.includes(value)}
                    onChange={() => handleOptionToggle(value)}
                  />
                  <span> {value}</span>
                </label>
              </div>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className="btn btn-default start-button"
          >
            {t("next_btn")}
            <img className="img-fluid" src={Arrow} alt="arrow" />
          </button>
        </div>
      ) : (
        Object.entries(parsedOptions).map(([key, value]) => (
          <label key={key}>
            <input type="checkbox" onChange={() => onAnswerSelect(value)} />
            {value}
          </label>
        ))
      )}
    </div>
  );
};

export default DynamicQuestions;
