// components/Slides/AdditionalGoalOptions.jsx
import React from "react";
import "../../assets/sass/_nutricheck.scss";
import Slim from "../../assets/images/SLIM_BODY 2.png";
import Average from "../../assets/images/SLIM_SHREDDED_BODY 2.png";
import Female from "../../assets/images/female.png";
import { useTranslation } from "react-i18next";

const AdditionalGoalOptions = ({
  onAdditionalGoalOptionSelect,
  onNextSlide,
}) => {
  const { t } = useTranslation();
  const selectedGender = localStorage.getItem("gender");
  const images =
    selectedGender === "female"
      ? {
          slim: Female,
          average: Female,
        }
      : {
          slim: Slim,
          average: Average,
        };
  return (
    <div className="additional-goal-options goal-selection">
      <h3>{t("specific_goal")}</h3>
      <div className="goal-options">
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Slim Body");
            onNextSlide();
          }}
        >
          <img src={images.slim} alt="slim-img" />
          <div>{t("slim_body")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Slim Shredded Body");
            onNextSlide();
          }}
        >
          <img src={images.average} alt="slim-img" />
          <div>{t("slim_shredded_body")}</div>
        </button>
        <button
          className="btn btn-default"
          onClick={() => {
            onAdditionalGoalOptionSelect("Slim Shredded Body");
            onNextSlide();
          }}
        >
          <img src={images.average} alt="slim-img" />
          <div>{t("Mental_Performance")}</div>
        </button>
      </div>
    </div>
  );
};

export default AdditionalGoalOptions;
