import React, { useState } from "react";
import { Slider, Button } from "antd";
import "../../assets/sass/_nutricheck.scss";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const FitnessLevelSelection = ({ onFitnessLevelSelect, onNextSlide }) => {
  const [fitnessLevel, setFitnessLevel] = useState(null);
  const { t } = useTranslation();
  const handleChange = (value) => {
    setFitnessLevel(value);
  };

  const handleNext = () => {
    if (fitnessLevel !== null) {
      onFitnessLevelSelect(fitnessLevel);
      onNextSlide();
    }
  };

  return (
    <div className="fitness-level-selection">
      <h3>{t("fitness_level")}</h3>
      <p>{t("fitness_level_p")}</p>
      <div className="slider-container">
        <Slider
          min={1}
          max={10}
          value={fitnessLevel}
          onChange={handleChange}
          marks={{ 1: "01", 10: "10" }}
          step={1}
          tooltipVisible
        />
      </div>
      <button
        className="start-button btn btn-default"
        onClick={handleNext}
        disabled={fitnessLevel === null}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default FitnessLevelSelection;
