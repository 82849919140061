import React from "react";
import Banner from "../components/Banner/Banner";
import Gallery from "../components/Gallery/Gallery";
import Characteristcs from "../components/Characteristcs/Characteristcs";
import Blogs from "../components/Blogs/Blogs";
import Testimonials from "../components/Testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <Banner />
      <div className="container">
        <Gallery />
        <Characteristcs />
        {/* <Blogs /> */}
        <Testimonials />
      </div>
    </>
  );
};

export default Home;
