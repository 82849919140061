import React, { useState } from "react";
import "../../assets/sass/_nutricheck.scss";
import { Slider } from "antd";
import Arrow from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const WorkPercentageSelection = ({ onWorkPercentageSelect, onNextSlide }) => {
  const [workPercentage, setWorkPercentage] = useState(0);
  const { t } = useTranslation();

  const handleWorkPercentageChange = (value) => {
    setWorkPercentage(value);
    onWorkPercentageSelect(value);
  };

  const formatter = (value) => `${value}h`;

  return (
    <div className="age-selection">
      <h3>{t("select_work_percentage")}</h3>
      <Slider
        min={0}
        max={100}
        value={workPercentage}
        onChange={handleWorkPercentageChange}
        tipFormatter={formatter}
      />
      <button
        className="start-button btn btn-default"
        onClick={onNextSlide}
        disabled={workPercentage === null}
      >
        {t("next_btn")}
        <img className="img-fluid" src={Arrow} alt="arrow" />
      </button>
    </div>
  );
};

export default WorkPercentageSelection;
